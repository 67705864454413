import Vue from 'vue'
import Router from 'vue-router'
import Screeners from "../components/boards/Screeners";
import Screener from "../components/boards/Screener";
import IRTExperiments from "../components/boards/IRTExperiments";
import VideoExperiments from "../components/boards/VideoExperiments";
import AllExperiments from "../components/boards/AllExperiments";
import InnerView from "../components/InnerView";
import Admins from "../components/boards/Admins";

Vue.use(Router)

export default new Router({
        routes: [
            {
                path: '/', component: InnerView,
                children: [
                    {path: '/', name: 'Screeners', component: Screeners},
                    {path: 'all-experiments', name: 'AllExperiments', component: AllExperiments},
                    {path: 'video-experiments', name: 'VideoExperiments', component: VideoExperiments},
                    {path: 'irt-experiments', name: 'IRTExperiments', component: IRTExperiments},
                    {path: 'screeners', name: 'Screeners', component: Screeners},
                    {path: 'admins', name: 'Admins', component: Admins},
                    {
                        path: '/screener',
                        name: 'Screener',
                        component: Screener,
                        props: route => (
                            { screener_id: route.query.screener_id }
                        )
                    },
                ]
            }
        ]
    }
)