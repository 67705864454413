<template>
  <div>
    <div class="popup_area login_form">
      <div class="popup error" id="error_form" style="display:none;">
        <div class="popup_message"><span>{{ $store.state.error }}</span></div>
        <div class="popup_close"><a class="close_button error_button" v-on:click="$store.dispatch('closeError')">Close</a></div>
      </div>
      <div class="popup message" id="message_form" style="display:none;">
        <div class="popup_message"><span>{{ $store.state.message }}</span></div>
        <div class="popup_close"><a class="close_button message_button" v-on:click="$store.dispatch('closeMessage')">Close</a></div>
      </div>
    </div>
    <div v-if="$store.state.logged_in === true">
      <MainView/>
    </div>
    <div v-else>
      <Login/>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Vuex from "vuex";
import VueCookies from "vue-cookies";
import VueI18n from "vue-i18n";
import axios from "axios";
import {messages} from "./components/translates.js"

import Login from "./components/Login";
import MainView from "./components/MainView";

Vue.use(Vuex);
Vue.use(VueCookies);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  messages: messages
})

const store = new Vuex.Store({
  state: {
    logged_in: false,
    locale: "ru",
    error: "",
    message: "",
    locales: [
      {
        locale: "en",
        language: "English"
      },
      {
        locale: "ja",
        language: "日本語"
      },
      {
        locale: "ru",
        language: "Русский"
      }
    ],
    user: {
      name: "",
      session: "",
      rights: []
    },
    experiments: [],
    active_experiment_id: 0,
    active_board: "all_experiments",
  },
  mutations: {
    logoutBecauseOfError(state, {error}) {
      if (error.response.status === 403) {
        state.logged_in = false;
        VueCookies.set("fastest_session", "");
      }
    },
    showError(state, error_text) {
      state.error = error_text;
      document.getElementById("error_form").style.display = "grid";
    },
    showMessage(state, message_text) {
      state.message = message_text;
      document.getElementById("message_form").style.display = "grid";
    },
    setRights(state, rights) {
      state.user.rights = rights;
    }
  },
  actions: {
    checkLogin() {
      var session = VueCookies.get("fastest_session");
      if (session === null) {
        this.state.logged_in = false;
      } else {
        axios({
          method: "get",
          url: "/api/check-login"
        }).then(response => {
          if (response.status === 200) {
            this.state.logged_in = true;
            this.commit("setRights", response.data["rights"]);
          } else {
            this.state.logged_in = false;
          }
        }).catch(error => {
          if (error.response.status === 403) {
            this.state.logged_in = false;
            this.commit("logoutBecauseOfError", {error});
            this.commit("setRights", []);
            this.commit("showError", "Session is corrupt");
          }
        });
      }
    },
    closeError() {
      document.getElementById("error_form").style.display = "none";
      this.state.error = "";
    },
    closeMessage() {
      document.getElementById("message_form").style.display = "none";
      this.state.message = "";
    },
    getUserInfo() {
      this.state.user.name = "mr.Admin";
    },
    clearUserInfo() {
      this.state.user.name = "";
    },
    getExperimentInfo() {
      axios({
        method: "get",
        url: "/api/info/experiment",
        params: {"experiment_id": this.state.active_experiment_id}
      }).then(response => {
        if (response.status === 200) {
          this.state.error = "";
          this.state.active_experiment_data.experiment_info = response.data;
        }
      }).catch(error => {
        this.state.error = error.response.data["error"];
      });
    }
  },
})


export default {
  name: "App",
  i18n: i18n,
  store: store,
  components: {
    Login,
    MainView,
  },
  methods: {},
  mounted() {
    axios({
      method: "get",
      url: "/api/get_csrf"
    }).then(response => {
      this.$cookies.set("csrftoken", response.data.token);
      axios.defaults.headers.common["X-CSRFToken"] = response.data.token;
    }).catch(error => {
      console.log(error);
    });
    this.$i18n.locale = this.$store.state.locale;
    this.$store.dispatch("checkLogin");
  }
}
</script>

<style>
html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

header {
  position: fixed;
  width: 100%;
  height: 70px;
  background: #2f2f2f;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  z-index: 100;
}

.header_button {
  min-width: 20px;
  height: 50px;
  max-width: 100px;
  padding: 15px 10px;
  margin: 0 10px;
  background: #201f1f;
  color: aliceblue;
  text-decoration: none;
}

.main_section {
  padding: 100px 0 130px;
  background: #965509;
  color: #201f1f;
  min-height: 100%;
}

h3 {
  height: 100px;
}

section {
}

.lang_selector {
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100px;
  color: aliceblue;
}

.centered {
  display: flex;
  justify-content: center;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}

:root {
  --button_color: #2A3F54;
  --hover-button_color: #1a2937;
}

.popup_area {
  position: fixed;
  top: 2%;
  display: grid;
  justify-content: right;
}

.popup {
  width: 10%;
  left: 50%;
  transform: translate(-10%, 0);
  padding: 30px;
  min-width: 300px;
  z-index: 1;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 10px;
}

.error {
  border: 3px solid #2A3F54;
  background-color: #96093a;
  color: white;
}

.error_button {
  background-color: #341f23;
  color: white;
}

.message {
  border: 3px solid #3388dd;
  background-color: #9faa96;
  color: #2b0909;
}

.message_button {
  background-color: #55554f;
  color: #2b0909;
}

.popup_message {
  text-align: center;
  margin-bottom: 10px;
  grid-area: 1 / 1 / 2 / 4;
}

.popup_close {
  grid-area: 2 / 3 / 3 / 4;
}

.close_button {
  min-width: 20px;
  height: 50px;
  max-width: 100px;
  padding: 5px 10px;
  margin: 0 10px;
  text-decoration: none;
  border-radius: 7px;
}

.close_button:hover {
  background: #293742;
  cursor: pointer;
}
</style>
