const messages = {
    en: {
        boards: {
            experiments: "Experiments",
            create_experiment: "New experiment",
            video_experiments: "Video experiments",
            irt_experiments: "IRT experiments",
            all_experiments: "All experiments",
            screeners: "Screeners",
        },
        message: {
            hello: "Welcome",
            log_out: "Log out",
            log_in: "Log in",
        },
        experiment: {
            videos: "Videos",
            text: "Text",
            questions: "Questions",
            project_name: "Project name",
            preview: "Preview",
            screener:{
                id: "ID",
                title: "Title",
                subtitle: "Subtitle or additional text.",
                screener_name: "Screener name(Users do not see it).",
                success_redirect: "Success redirect",
                fail_redirect: "Fail redirect",
                slides: "Slides",
                send: "Create screener",
                add_slide: "Add slide",
                answers: "Answers",
                up_slide: "Move slide up",
                down_slide: "Move slide down",
                delete_slide: "Remove slide",
                add_answer: "Add answer",
                question_text: "Question text",
                general_info: "General information",
                pass_answers: "Pass answers",
                any_pass: "Any answer is correct",
                create_new: "Create new screener",
                update: "Update screener data",
                return: "Return",
                remove_answer: "Remove answer",
                slide: "Slide",
                question: "Question",
                chains: "Answer chains",
                delete_chain: "Delete chain",
                add_chain: "Add new chain",
            }
        },
        elements: {
            update_experiment: "Update experiment",
            create_experiment: "Create experiment",
        },
        errors: {
            experiment_not_found: "Experiment was not found.",
            session_corrupt: "Session is corrupted.",
        },
        columns: {
            active: "Active",
            name: "Name",
            title: "Shown title",
            comment: "Comment",
            link: "Link",
            edit: "Edit",
            id: "Visible ID",
        }
    },
    ja: {
        boards: {
            experiments: "実験",
            create_experiment: "実験 を 作成 する",
            video_experiments: "ビデオ 実験",
            irt_experiments: "IRT 実験",
            all_experiments: "実験",
            screeners: "スクリーナー",
        },
        message: {
            hello: "こんにちは",
            log_out: "ログアウト",
            log_in: "ログイン",
        },
        experiment: {
            videos: "ビデオ",
            text: "テキスト",
            questions: "質問",
            project_name: "プロジェクト 名",
            preview: "内見",
            screener:{
                id: "ID",
                title: "題名",
                subtitle: "字幕または説明テキスト",
                success_redirect: "成功リダイレクト",
                screener_name: "スクリーナー名",
                fail_redirect: "失敗リダイレクト",
                slides: "スライド",
                send: "スクリーナーを作成する",
                add_slide: "スライドを追加",
                answers: "答え",
                up_slide: "スライドを上に移動",
                down_slide: "スライドを下に移動",
                delete_slide: "スライドを削除します",
                add_answer: "答えを追加",
                question_text: "質問テキスト",
                general_info: "一般情報",
                pass_answers: "正解",
                any_pass: "どんな答えも正解です",
                create_new: "新しいスクリーナーを作成する",
                update: "スクリーナーデータを更新する",
                return: "リターン",
                remove_answer: "答えを削除してください",
                slide: "スライド",
                question: "質問",
                chains: "応答シーケンス",
                delete_chain: "チェーンを削除",
                add_chain: "新しいチェーンを追加する",
            }
        },
        elements: {
            update_experiment: "実験の更新",
            create_experiment: "実験を作成する",
        },
        errors: {
            experiment_not_found: "実験は見つかりませんでした.",
            session_corrupt: "セッションが破損しています.",
        },
        columns: {
            active: "アクティブ",
            name: "タイトル",
            title: "表示されたタイトル",
            comment: "コメント",
            link: "リンク",
            edit: "編集",
            id: "表示されるID",
        }
    },
    ru: {
        boards: {
            experiments: "Исследования",
            create_experiment: "Новое исследование",
            video_experiments: "Видео исследование",
            irt_experiments: "IRT исследования",
            all_experiments: "Все исследования",
            screeners: "Скринеры",
        },
        message: {
            hello: "Здравствуйте",
            log_out: "Выйти",
            log_in: "Войти",
        },
        experiment: {
            videos: "Видео",
            text: "Текст",
            questions: "Вопросы",
            project_name: "Название проекта",
            preview: "Предпросмотр",
            screener:{
                id: "ID",
                title: "Заголовок скринера",
                subtitle: "Подзаголовок или пояснительный текст.",
                success_redirect: "Путь в случае успеха",
                screener_name: "Название скринера(не отображается пользователям)",
                fail_redirect: "Путь в случае неуспеха",
                slides: "Слайды",
                send: "Создать скринер",
                add_slide: "Добавить слайд",
                answers: "Ответы",
                up_slide: "Поднять слайд",
                down_slide: "Опустить слайд",
                delete_slide: "Удалить слайд",
                add_answer: "Добавить ответ",
                question_text: "Текст вопроса",
                general_info: "Общая информация",
                pass_answers: "Правильные ответы",
                any_pass: "Пропускать в любом случае",
                create_new: "Создать новый скринер",
                update: "Обновить скринер",
                return: "Вернуться",
                remove_answer: "Удалить ответ",
                slide: "Слайд",
                question: "Вопрос",
                chains: "Цепочки ответов",
                delete_chain: "Удалить цепочку",
                add_chain: "Добавить новую цепочку",
            }
        },
        elements: {
            update_experiment: "Обновить эксперимент",
            create_experiment: "Создать новый эксперимент",
        },
        errors: {
            experiment_not_found: "Эксперимент не найден.",
            session_corrupt: "Сессия закончена или не корректна.",
        },
        columns: {
            active: "Активный",
            name: "Название",
            title: "Отображаемое название",
            comment: "Комментарий",
            link: "Ссылка",
            edit: "Редактировать",
            id: "Отображаемый ID",
        }
    },
}

export {messages}
