<template>
  <div class="right_col experiment_page_main" role="main">
    <div class="experiment">
      <h3 style="height: 40px">{{ $t('boards.irt_experiments') }}</h3>
      <table>
        <thead>
        <tr>
          <th>{{ $t('experiment.project_name') }}</th>
          <th>{{ $t('experiment.questions') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="experiment in $store.state.experiments.irt_experiments">
          <th><a v-on:click="showExperiment(experiment.id)">{{ experiment.project_name }}</a></th>
          <th>{{ experiment.questions }}</th>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="experiment experiment_creator">
      <span>LOL</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "IRTExperiments",
  methods: {
    showExperiment(new_experiment_id) {
      this.$store.state.active_experiment_id = new_experiment_id;
    }
  },
  mounted() {
    this.$store.dispatch("checkLogin");
  }
}
</script>

<style scoped>

</style>