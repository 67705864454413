<template>
  <div class="right_col experiment_page_main" role="main">
    <div class="experiment">
      <h3 style="height: 40px">{{ $t('boards.screeners') }}</h3>
      <div class="center">
        <table id="screeners_table">
          <thead>
          <tr>
            <th class="col5">{{ $t('columns.active') }}</th>
            <th class="col20">{{ $t('columns.id') }}</th>
            <th class="col10">{{ $t('columns.name') }}</th>
            <th class="col20">{{ $t('columns.title') }}</th>
            <th class="col40">{{ $t('columns.comment') }}</th>
            <th class="col5">{{ $t('columns.link') }}</th>
            <th class="col5">{{ $t('columns.edit') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="screener in screeners">
            <td v-if="screener['active']">
              <a class="screener_button screener_narrow_button green" v-on:click="activeStatusChange(screener['id'])">Enabled</a>
            </td>
            <td v-else>
              <a class="screener_button screener_narrow_button red" v-on:click="activeStatusChange(screener['id'])">Disabled</a>
            </td>
            <td>{{ screener["id"] }}</td>
            <td>{{ screener["screener_name"] }}</td>
            <td>{{ screener["title"] }}</td>
            <td>{{ screener["comment"] }}</td>
            <td v-if="screener['active']">
              <a class="screener_button screener_narrow_button"
                 :href="'/execute/screener?screener_id=' + screener['id']">
                {{ $t('columns.link') }}
              </a>
            </td>
            <td v-else class="disabled">
              <a class="screener_button screener_narrow_button"
                 :href="'/execute/screener?screener_id=' + screener['id']">
                {{ $t('columns.link') }}
              </a>
            </td>
            <td>
              <router-link class="screener_button screener_narrow_button"
                           :to="'./screener?screener_id=' + screener['id']">
                {{ $t('columns.edit') }}
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <router-link to="./screener" class="screener_button bottom_button">
        {{ $t("experiment.screener.create_new") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ScreenerCreator",
  props: {
    lol: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      screener_data: {
        type: "block",
        subtype: "screener",
        active: true,
        screener_name: "",
        success_redirect: "",
        fail_redirect: "",
        title: "",
        subtitle: "",
        slides: [],
        pass_answers: [],
        screener_type: "singleton",
        comment: "",
      },
      screener_representation: {
        any_pass: false
      },
      slide_options: [
        "screener_slide",
        "screener_image_slide",
        "screener_image_block_slide"
      ],
      screener_types: [
        "singleton",
        "chained"
      ],
      screeners: [],
      new_screener: true,
      mode: "general_info"
    }
  },
  methods: {
    // sendData() {
    //   let method;
    //   if (this.$data.new_screener) {
    //     method = "put";
    //   } else {
    //     method = "post";
    //   }
    //   console.log(this.$data)
    //   axios({
    //     method: method,
    //     url: "/api/create/screener",
    //     data: this.$data.screener_data,
    //   }).then(response => {
    //     console.log(response.status)
    //   }).catch(error => {
    //     console.log(error);
    //   });
    // },
    // addSlide() {
    //   this.$data.screener_data.slides.push(
    //       {
    //         question_text: "",
    //         slide_type: "screener_slide",
    //         answers: [],
    //       }
    //   )
    // },
    // removeSlide(slide_to_remove) {
    //   this.$data.screener_data.slides.splice(slide_to_remove, 1);
    // },
    // addAnswer(slide_to_add_answer) {
    //
    // },
    // downSlide(slide_to_down) {
    //   if (slide_to_down < (this.$data.screener_data.slides.length - 1)) {
    //     var temp_slide = this.$data.screener_data.slides[slide_to_down];
    //     this.$data.screener_data.slides[slide_to_down] = this.$data.screener_data.slides[slide_to_down + 1];
    //     this.$data.screener_data.slides[slide_to_down + 1] = temp_slide;
    //     this.$forceUpdate();
    //   }
    // },
    // upSlide(slide_to_up) {
    //   if (slide_to_up > 0) {
    //     var temp_slide = this.$data.screener_data.slides[slide_to_up];
    //     this.$data.screener_data.slides[slide_to_up] = this.$data.screener_data.slides[slide_to_up - 1];
    //     this.$data.screener_data.slides[slide_to_up - 1] = temp_slide;
    //     this.$forceUpdate();
    //   }
    // },
    changeType(slide_to_change) {
      alert(this.screener_data.slides[slide_to_change].slide_type);
      console.log(slide_to_change);
    },
    // editScreener(screener_id) {
    //   axios({
    //     method: "get",
    //     url: "/api/create/screener",
    //     params: {"screener_id": screener_id},
    //   }).then(response => {
    //     this.$data.screener_data = response.data;
    //     for (let slide in this.$data.screener_data.slides) {
    //       this.$data.screener_data.slides[slide].slide_type = this.$data.screener_data.slides[slide].type;
    //     }
    //   }).catch(error => {
    //     console.log(error);
    //   });
    // },
    anyPassSet() {
      document.getElementById("pass_answers").classList.add("disabled")
    },
    activeStatusChange(screener_id) {
      axios({
        method: "post",
        url: "/api/update/screener/enabled",
        params: {
          screener_id: screener_id
        }
      }).then(response => {
        this.getScreeners();
      }).catch(error => {
        console.log(error);
      });

    },
    getScreeners() {
      axios({
        method: "get",
        url: "/api/info/screeners"
      }).then(response => {
        this.screeners = response.data["screeners"];
        console.log(this.$store.state.experiments);
      }).catch(error => {
        console.log(error);
      });
    },
  },
  mounted() {
    this.$store.dispatch("checkLogin");
    this.getScreeners();
  },
}
</script>

<style scoped>
input {
  width: 100%;
  height: 20px;
}

select {
  width: 100%;
  height: 20px;
}

.screener_button {
  padding: 17px 8px;
  display: flex;
  justify-content: space-around;
  color: #ffffff !important;
  background-color: var(--button_color);
  margin: 2px;
}

.screener_button:hover {
  color: #ffffff !important;
  background-color: var(--hover-button_color);
  cursor: pointer;
}

.screener_narrow_button {
  padding: 3px 8px;
}

.disabled {
  pointer-events: none;
}

.disabled a {
  background-color: #7f808e;
}

.green {
  background-color: green;
}

.red {
  background-color: red;
}

#screeners_table table {
  border-collapse: collapse;
}

#screeners_table tr {
  border: none;
}

#screeners_table td {
  border-right: solid 1px var(--button_color);
  border-left: solid 1px var(--button_color);
  padding-left: 5px;
  padding-right: 5px;
}

#screeners_table th {
  border-right: solid 1px var(--button_color);
  border-left: solid 1px var(--button_color);
  text-align: center;
}

.col5 {
  width: 5vh;
}

.col10 {
  width: 10vh;
}

.col20 {
  width: 20vh;
}

.col40 {
  width: 40vh;
}

#screeners_table tr:nth-child(even) {
  background-color: #e1e1e1;
}

.center {
  display: flex;
  justify-content: center;
}

.bottom_button {
  margin: 30px 0px 10px;
}
</style>