<template>
  <div class="right_col experiment_page_main" role="main">
    <div class="experiment">
    <div class="row" style="display: inline-block;">
      <div class="tile_count">
        <h3 style="height: 40px">{{ $t('boards.video_experiments') }}</h3>
        <table>
          <thead>
          <tr>
            <th>{{ $t('experiment.project_name') }}</th>
            <th>{{ $t('experiment.videos') }}</th>
            <th>{{ $t('experiment.questions') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="experiment in $store.state.experiments.video_experiments">
            <th><a v-on:click="showExperiment(experiment.id)">{{ experiment.project_name }}</a></th>
            <th>{{ experiment.videos }}</th>
            <th>{{ experiment.questions }}</th>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
    <div class="experiment experiment_creator">
      <span>KEK</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoExperiments",
  methods: {
    showExperiment(new_experiment_id) {
      this.$store.state.active_experiment_id = new_experiment_id;
    }
  },
  mounted() {
    this.$store.dispatch("checkLogin");
  }
}
</script>

<style scoped>

</style>