<template>
  <div class="right_col experiment_page_main" role="main">
    <div class="experiment">
      <div class="segmentation" style="justify-content: space-between;display:flex;flex-direction: row">
        <div>
          <label><input type="radio" value="general_info" v-model="mode">
            {{ $t('experiment.screener.general_info') }}</label>
          <label><input type="radio" value="slides" v-model="mode">
            {{ $t('experiment.screener.slides') }}</label>
          <label><input type="radio" value="pass_answers" v-model="mode"
                        v-on:click="completeUnAddedFieldsToPassAnswers()">
            {{ $t('experiment.screener.pass_answers') }}</label>
        </div>
        <a :href="'/execute/screener?screener_id=' + screener_id" class="screener_button" target="_blank">
          {{ $t("experiment.preview") }}
        </a>
      </div>
      <div v-if="mode === 'general_info'">
        <div class="segmentation">
          <input v-model="screener_data.id" name="id"
                 :placeholder="$t('experiment.screener.id')">
        </div>
        <div class="segmentation">
          <input v-model="screener_data.screener_name" name="screener_name"
                 :placeholder="$t('experiment.screener.screener_name')">
        </div>
        <div class="segmentation">
          <input v-model="screener_data.success_redirect" name="success_redirect"
                 :placeholder="$t('experiment.screener.success_redirect')">
        </div>
        <div class="segmentation">
          <input v-model="screener_data.fail_redirect" name="fail_redirect"
                 :placeholder="$t('experiment.screener.fail_redirect')">
        </div>
        <div class="segmentation">
          <input v-model="screener_data.title" name="title" :placeholder="$t('experiment.screener.title')">
        </div>
        <div class="segmentation">
          <textarea v-model="screener_data.subtitle" name="subtitle" :placeholder="$t('experiment.screener.subtitle')">
          </textarea>
        </div>
      </div>
      <div v-else-if="mode === 'slides'">
        <div class="segmentation input_block">
          <span>{{ $t("experiment.screener.slides") }}</span>
          <a v-on:click="addSlide()" class="screener_button">{{ $t("experiment.screener.add_slide") }}</a>
          <div v-for="(slide, index) in screener_data.slides" class="slide">
            <div style="display: flex;flex-direction: column">
              <div style="display: flex;justify-content: space-between">
                <div style="display: flex">
                  <div style="display: flex;flex-direction: column">
                    <a v-on:click="upSlide(index)"
                       class="screener_button screener_narrow_button">{{ $t("experiment.screener.up_slide") }}</a>
                    <a v-on:click="downSlide(index)"
                       class="screener_button screener_narrow_button">{{ $t("experiment.screener.down_slide") }}</a>
                  </div>
                  <a v-on:click="removeSlide(index)" class="screener_button">
                    {{ $t("experiment.screener.delete_slide") }}
                  </a>
                  <a v-on:click="addAnswer(index)" class="screener_button">
                    {{ $t("experiment.screener.add_answer") }}
                  </a>
                </div>
                <span style="font-size: 20px;padding-right: 20px;padding-top: 10px">{{ slide.order }}</span>
              </div>
              <div style="display: flex;flex-direction: column;margin: 2px">
                <select v-model="screener_data.slides[index].type" style="margin-bottom: 5px"
                        v-on:change="changeSlideType(index)">
                  <option v-for="slide_option in slide_options" :value="slide_option">
                    {{ slide_option }}
                  </option>
                </select>
                <input v-model="slide.question_text" name="question_text"
                       :placeholder="$t('experiment.screener.question_text')">
              </div>
            </div>
            <h5 style="padding-top: 3px;margin-bottom: 2px">{{ $t("experiment.screener.answers") }}</h5>
            <div v-for="(answer, answer_index) in slide.answers" :key="answer.number"
                 style="display: flex;margin-bottom: 3px">
              <input class="answer_input" v-model="answer.text">
              <div>
                <button class="screener_button screener_extra_narrow_button"
                        v-on:click="upAnswer(index, answer_index)">🠕
                </button>
                <button class="screener_button screener_extra_narrow_button"
                        v-on:click="downAnswer(index, answer_index)">🠗
                </button>
              </div>
              <button class="screener_button screener_narrow_wide_button"
                      v-on:click="removeAnswer(index, answer_index)">{{ $t("experiment.screener.remove_answer") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="mode === 'pass_answers'">
        <div class="segmentation">
          <select v-model="screener_data.screener_type" style="margin-bottom: 5px" v-on:change="setScreenerType()">
            <option v-for="screener_type in screener_types" :value="screener_type">
              {{ screener_type }}
            </option>
          </select>
        </div>
        <div class="segmentation">
          <label style="display: flex">
            <input type="checkbox" v-model="screener_representation.any_pass" v-on:click="anyPassSet()"
                   style="width:10px;margin-right: 10px">
            {{ $t('experiment.screener.any_pass') }}
          </label>
        </div>
        <div v-if="screener_data.screener_type === 'chained'" class="segmentation">
          <span>Chained</span>
          <div id="pass_answers_chained" class="segmentation">
            <form id="new_chain_form">
              <div v-for="slide in screener_data.slides">
                <span>{{ slide.order }}: {{ slide.question_text }}</span>
                <select :id="'sel_' + slide.order">
                  <option v-for="answer in slide.answers" :value="answer.number">{{ answer.number }}:
                    {{ answer.text }}
                  </option>
                </select>
              </div>
              <button class="screener_button" v-on:click="addAnswerChain()">{{
                  $t('experiment.screener.add_chain')
                }}
              </button>
            </form>
            <span>{{ $t('experiment.screener.chains') }}:</span>
            <div v-for="(pass_answer, answer_index) in screener_representation.chained_pass_answers" class="left_row">
              <span>{{ pass_answer }}</span>
              <button class="screener_button screener_narrow_button" style="margin-left: 10px"
                      v-on:click="removeAnswerChain(answer_index)">{{ $t('experiment.screener.delete_chain') }}
              </button>
            </div>
          </div>
        </div>
        <div v-else-if="screener_data.screener_type === 'singleton'" class="segmentation">
          <span>Singleton</span>
          <div v-for="(slide, slide_index) in screener_data.slides" style="display: flex;flex-direction: column">
            <span style="padding-top: 10px">{{ $t("experiment.screener.slide") }} №{{ slide.order }}</span>
            <span>{{ $t("experiment.screener.question") }}: "{{ slide.question_text }}"</span>
            <span>{{ $t("experiment.screener.answers") }}:</span>
            <div v-for="(answer, answer_index) in slide.answers">
              <span>{{ screener_representation.singleton_pass_answers[slide.order] }}</span>
              <button v-if="screener_representation.singleton_pass_answers[slide.order].includes(answer.number)"
                      class="screener_button screener_narrow_button pressed"
                      :id="slide.order + '_' + answer.number"
                      v-on:click="setPassAnswerForSingleton(slide.order, answer.number)">
                {{ answer["number"] }}:'{{ answer["text"] }}'
              </button>
              <button v-else
                      class="screener_button screener_narrow_button"
                      :id="slide.order + '_' + answer.number"
                      v-on:click="setPassAnswerForSingleton(slide.order, answer.number)">
                {{ answer["number"] }}:'{{ answer["text"] }}'
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="segmentation button_row">
        <router-link class="screener_button router_link" to="./screeners">{{
            $t("experiment.screener.return")
          }}
        </router-link>
        <a v-if="screener_id === ''" v-on:click="sendData()"
           class="screener_button router_link">{{ $t("experiment.screener.send") }}</a>
        <a v-else v-on:click="sendData()" class="screener_button router_link">{{
            $t("experiment.screener.update")
          }}</a>
      </div>
    </div>
    <div class="experiment">
      <button v-on:click="showStatsData()">View experiment stats.</button>
      <button v-on:click="loadRawStatsData()">Get raw experiments stats.</button>
      <label><input type="checkbox" v-model="screener_stats_filter" v-on:click="getScreenerStats()">Show only
        passed</label>
      <div style="display: none" id="stats">
        <div v-for="stat in screener_stats">
          <div style="display: flex;flex-direction: column">
            <span class="stats_question">{{ stat.question_text }}</span>
            <span v-for="answer in stat.answers" class="stats_answer">
              {{ answer.text }}: {{ answer.count }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="experiment">
      <button v-on:click="showRawData()">Raw screener JSON</button>
      <div style="display: none" id="screener_raw">
        <textarea style="width: 100%;min-height: 300px">{{screener_data}}</textarea>
        <button v-on:click="sendData()">Send raw data</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Screener",
  props: {
    screener_id: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      screener_data: {
        id: "",
        type: "block",
        subtype: "screener",
        active: true,
        screener_name: "",
        success_redirect: "",
        fail_redirect: "",
        title: "",
        subtitle: "",
        slides: [],
        pass_answers: [],
        screener_type: "singleton",
        comment: "",
      },
      screener_representation: {
        any_pass: false,
        singleton_pass_answers: {},
        chained_pass_answers: [],
        new_chain: {},
      },
      slide_options: [
        "screener_slide",
        "screener_image_slide",
        "screener_image_block_slide",
      ],
      screener_types: [
        "singleton",
        "chained",
      ],
      image_types: [
        "simple",
        "aws",
      ],
      screeners: [],
      new_screener: true,
      mode: "general_info",
      screener_stats: {},
      screener_stats_filter: true,
    }
  },
  methods: {
    sendData() {
      let method;
      if (this.screener_id !== "") {
        method = "post";
      } else {
        method = "put";
      }
      if (this.$data.screener_data.screener_type === "") {
        this.$data.screener_data.screener_type = "chained";
      }
      if (this.$data.screener_data.screener_type === "chained") {
        this.$data.screener_data.pass_answers = this.$data.screener_representation.chained_pass_answers;
      } else if (this.$data.screener_data.screener_type === "singleton") {
        this.$data.screener_data.pass_answers = this.$data.screener_representation.singleton_pass_answers;
      }

      axios({
        method: method,
        url: "/api/create/screener",
        data: this.$data.screener_data,
      }).then(response => {
        this.$store.commit("showMessage", "Screener saved")
        this.$router.push({path: "screener", query: {screener_id: response.data["screener_id"]}});
      }).catch(error => {
        console.log(error);
      });
    },
    reorderSlidesAndAnswers() {
      let i = 1;
      for (let slide_id in this.screener_data.slides) {
        this.screener_data.slides[slide_id].order = i;
        i++;
        let j = 1;
        for (let answer_id in this.screener_data.slides[slide_id].answers) {
          this.screener_data.slides[slide_id].answers[answer_id].number = j;
          j++;
        }
      }
      this.readyNewChain();
    },
    readyNewChain() {
      for (let i; i < this.screener_data.slides.length; i++) {
        this.screener_representation.new_chain[i.toString()] = "";
      }
    },
    addSlide() {
      this.$data.screener_data.slides.push(
          {
            question_text: "",
            type: "screener_slide",
            answers: [],
          }
      )
      for (let i = 0; i < this.screener_representation.chained_pass_answers.length; i++) {
        this.screener_representation.chained_pass_answers[i][this.screener_representation.chained_pass_answers[i].length + 1] = 0
      }
    },
    changeSlideType(slide_index_to_change_type) {
      if (this.screener_data.slides[slide_index_to_change_type].type === "screener_slide") {

      } else if (this.screener_data.slides[slide_index_to_change_type].type === "screener_image_slide") {
        if (this.screener_data.slides[slide_index_to_change_type].hasOwnProperty("question_image") === false) {
          this.screener_data.slides[slide_index_to_change_type]["question_image"] = {};
        }
      } else if (this.screener_data.slides[slide_index_to_change_type].type === "screener_image_block_slide") {
        if (this.screener_data.slides[slide_index_to_change_type].hasOwnProperty("question_image_block") === false) {
          this.screener_data.slides[slide_index_to_change_type]["question_image_block"] = [];
        }
      }
    },
    upSlide(slide_to_up) {
      if (slide_to_up > 0) {
        let temp_slide = this.screener_data.slides[slide_to_up];
        this.screener_data.slides[slide_to_up] = this.screener_data.slides[slide_to_up - 1];
        this.screener_data.slides[slide_to_up - 1] = temp_slide;
        let temp_sing_answer = this.screener_representation.singleton_pass_answers[slide_to_up];
        this.screener_representation.singleton_pass_answers[slide_to_up] =
            this.screener_representation.singleton_pass_answers[slide_to_up - 1];
        this.screener_representation.singleton_pass_answers[slide_to_up - 1] = temp_sing_answer;
        for (let chaned_answer_id in this.screener_representation.chained_pass_answers) {
          let temp_chaned_answer = this.screener_representation.chained_pass_answers[chaned_answer_id][slide_to_up];
          this.screener_representation.chained_pass_answers[chaned_answer_id][slide_to_up] =
              this.screener_representation.chained_pass_answers[chaned_answer_id][slide_to_up - 1];
          this.screener_representation.chained_pass_answers[chaned_answer_id][slide_to_up - 1] = temp_chaned_answer;
        }
        this.reorderSlidesAndAnswers();
      }
    }, //Test
    downSlide(slide_to_down) {
      if (slide_to_down < (this.screener_data.slides.length - 1)) {
        var temp_slide = this.screener_data.slides[slide_to_down];
        this.screener_data.slides[slide_to_down] = this.screener_data.slides[slide_to_down + 1];
        this.screener_data.slides[slide_to_down + 1] = temp_slide;
        let temp_sing_answer = this.screener_representation.singleton_pass_answers[slide_to_up];
        this.screener_representation.singleton_pass_answers[slide_to_up] =
            this.screener_representation.singleton_pass_answers[slide_to_up + 1];
        this.screener_representation.singleton_pass_answers[slide_to_up + 1] = temp_sing_answer;
        for (let chaned_answer_id in this.screener_representation.chained_pass_answers) {
          let temp_chaned_answer = this.screener_representation.chained_pass_answers[chaned_answer_id][slide_to_up];
          this.screener_representation.chained_pass_answers[chaned_answer_id][slide_to_up] =
              this.screener_representation.chained_pass_answers[chaned_answer_id][slide_to_up + 1];
          this.screener_representation.chained_pass_answers[chaned_answer_id][slide_to_up + 1] = temp_chaned_answer;
        }
        this.reorderSlidesAndAnswers();
      }
    }, //Test
    removeSlide(slide_to_remove) {
      this.screener_data.slides.splice(slide_to_remove, 1);
    }, //Finish
    addAnswer(slide_to_add_answer_index) {
      this.screener_data.slides[slide_to_add_answer_index].answers.push(
          {
            text: "",
            number: 0
          }
      );
      this.reorderSlidesAndAnswers();
    }, //Finish
    upAnswer(slide_answer_index, answer_to_up_index) {
      if (answer_to_up_index > 0) {
        let temp_answer = this.screener_data.slides[slide_answer_index].answers[answer_to_up_index];
        this.screener_data.slides[slide_answer_index].answers[answer_to_up_index] = this.screener_data.slides[slide_answer_index].answers[answer_to_up_index - 1]
        this.screener_data.slides[slide_answer_index].answers[answer_to_up_index - 1] = temp_answer;
        this.reorderSlidesAndAnswers();
      }
    }, //Finish
    downAnswer(slide_answer_index, answer_to_down_index) {
      if (answer_to_down_index < (this.screener_data.slides[slide_answer_index].answers.length - 1)) {
        var temp_slide = this.screener_data.slides[slide_answer_index].answers[answer_to_down_index];
        this.screener_data.slides[slide_answer_index].answers[answer_to_down_index] = this.screener_data.slides[slide_answer_index].answers[answer_to_down_index + 1];
        this.screener_data.slides[slide_answer_index].answers[answer_to_down_index + 1] = temp_slide;
        this.reorderSlidesAndAnswers();
      }
    }, //Finish
    removeAnswer(slide_to_remove_answer_index, answer_to_remove_index) {
      this.screener_data.slides[slide_to_remove_answer_index].answers.splice(answer_to_remove_index, 1);
      this.reorderSlidesAndAnswers();
    }, //Finish
    changeType(slide_to_change) {
      console.log(slide_to_change);
    },
    getExistingScreener(screener_id) {
      axios({
        method: "get",
        url: "/api/create/screener",
        params: {"screener_id": screener_id},
      }).then(response => {
        this.screener_data = response.data;
        if (this.screener_data.screener_type === "singleton") {
          this.screener_representation.singleton_pass_answers = this.screener_data.pass_answers;
        } else {
          this.screener_representation.chained_pass_answers = this.screener_data.pass_answers;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    anyPassSet() {
      if (this.screener_representation.any_pass) {
        document.getElementById("pass_answers_chained").classList.add("disabled");
        document.getElementById("pass_answers_singleton").classList.add("disabled");
      } else {
        document.getElementById("pass_answers_chained").classList.remove("disabled");
        document.getElementById("pass_answers_singleton").classList.remove("disabled");
      }
    },
    showRawData() {
      // document.getElementById("screener_raw").innerText = JSON.stringify(this.screener_data, undefined, 4);
      if (document.getElementById("screener_raw").style.display === "block") {
        document.getElementById("screener_raw").style.display = "none";
      } else {
        document.getElementById("screener_raw").style.display = "block";
      }
    },
    showStatsData() {
      if (document.getElementById("stats").style.display === "block") {
        document.getElementById("stats").style.display = "none";
      } else {
        this.getScreenerStats();
        document.getElementById("stats").style.display = "block";
      }
    },
    loadRawStatsData() {
      axios({
        method: "get",
        url: "/api/info/experiment/stats-raw",
        params: {"screener_id": this.screener_id},
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${this.screener_id}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    },
    getScreenerStats() {
      var params = {"screener_id": this.screener_id};
      if (this.screener_stats_filter) {
        params["passed"] = "true"
      }
      axios({
        method: "get",
        url: "/api/info/experiment/stats",
        params: params,
      }).then(response => {
        this.screener_stats = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    setScreenerType() {
      if (this.screener_data.screener_type === "singleton") {
        this.screener_data.pass_answers = this.screener_representation.singleton_pass_answers;
      } else {
        this.screener_data.pass_answers = this.screener_representation.chained_pass_answers;
      }
    },
    setPassAnswerForSingleton(slide_order, answer_number) {
      if (this.screener_representation.singleton_pass_answers[slide_order].includes(answer_number)) {
        for (let i = 0; i < this.screener_representation.singleton_pass_answers[slide_order].length; i++) {
          if (this.screener_representation.singleton_pass_answers[slide_order][i] === answer_number) {
            this.screener_representation.singleton_pass_answers[slide_order].splice(i, 1);
          }
        }
        document.getElementById(slide_order + "_" + answer_number).classList.remove("pressed");
      } else {
        this.screener_representation.singleton_pass_answers[slide_order].push(answer_number);
        document.getElementById(slide_order + "_" + answer_number).classList.add("pressed");
      }
    },
    removeAnswerChain(answer_chain_index) {
      this.screener_representation.chained_pass_answers.splice(answer_chain_index, 1);
    },
    addAnswerChain() {
      let new_chain = {};
      for (let i = 0; i < this.screener_data.slides.length; i++) {
        let slide = this.screener_data.slides[i]
        let idx = document.getElementById("sel_" + slide.order).selectedIndex;
        let selected_pass_answer = parseInt(document.getElementById("sel_" + slide.order).options[idx].value);
        new_chain[slide.order] = selected_pass_answer;
      }
      this.screener_representation.chained_pass_answers.push(new_chain)
    },
    completeUnAddedFieldsToPassAnswers() {
      if (Array.isArray(this.screener_representation.singleton_pass_answers)) {
        this.screener_representation.singleton_pass_answers = {}
      }
      for (let slide = 0; slide < this.screener_data.slides.length; slide++) {
        let slide_id = (parseInt(slide) + 1).toString();
        for (let chain_id = 0; chain_id < this.screener_representation.chained_pass_answers.length; chain_id++) {
          let chain = this.screener_representation.chained_pass_answers[chain_id];
          if (slide_id in chain) {
          } else {
            chain[slide_id] = 0;
          }
        }
        if (slide_id in this.screener_representation.singleton_pass_answers) {
        } else {
          this.screener_representation.singleton_pass_answers[slide_id] = []
        }
      }
    },
  },
  mounted() {
    if (this.screener_id !== "") {
      this.getExistingScreener(this.screener_id);
      this.completeUnAddedFieldsToPassAnswers();
    }
  }
}
</script>

<style scoped>
.segmentation {
  margin: 7px;
}

input {
  width: 100%;
  height: 20px;
}

.segmentation textarea {
  width: 100%;
  height: 80px;
}

select {
  width: 100%;
  height: 20px;
}

.input_block {
  display: flex;
  flex-direction: column;
}

.screener_button {
  padding: 17px 8px;
  display: flex;
  justify-content: space-around;
  color: #ffffff !important;
  background-color: var(--button_color);
  margin: 2px;
}

.screener_button:hover {
  color: #ffffff !important;
  background-color: var(--hover-button_color);
  cursor: pointer;
}

.screener_narrow_button {
  padding: 3px 8px;
}

.screener_extra_narrow_button {
  padding: 0 8px;
  font-size: 7px;
}

.screener_narrow_wide_button {
  padding: 3px 8px;
  max-height: 30px;
  width: 200px;
}

.pressed {
  background: #1c650b;
}

.pressed:hover {
  background: #144b09;
}

.slide {
  display: flex;
  flex-direction: column;
  border: var(--button_color) solid 2px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.disabled {
  pointer-events: none;
}

.disabled a {
  background-color: #7f808e;
}

.left_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.left_row span {
  padding-top: 3px;
}

#screeners_table table {
  border-collapse: collapse;
}

#screeners_table tr {
  border: none;
}

#screeners_table td {
  border-right: solid 1px var(--button_color);
  border-left: solid 1px var(--button_color);
  padding-left: 5px;
  padding-right: 5px;
}

#screeners_table th {
  border-right: solid 1px var(--button_color);
  border-left: solid 1px var(--button_color);
  text-align: center;
}

.answer_input {
  height: 30px;
  margin-top: 1px;
  margin-right: 4px;
  margin-left: 4px;
}

#screeners_table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.button_row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.router_link {
  width: 40%;
}

.stats_question {
  font-weight: bold;
}

.stats_answer {
  margin-left: 10px;
}
</style>