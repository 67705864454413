<template>
  <div class="right_col" role="main">
    <div class="row" style="display: inline-block;">
      <div v-if="$store.state.error !== ''">
        <span>{{ $t('errors.' + $store.state.error) }}</span>
      </div>
      <div v-else class="tile_count">
        <span>Experiment {{ $store.state.active_experiment_id }} view.</span>
        <form id="demo" v-on:submit.prevent="send_experiment()">
          <p v-for="block in $store.state.active_experiment_data.experiment_info">
            <span>{{ block }}</span>
            <input type="text" value="">
          </p>
          <p>
            <input type="text" v-model="$store.state.active_experiment_data.msg">
          </p>
          <p>
            <input type="checkbox" v-model="$store.state.active_experiment_data.checked">
          </p>
          <p>
            <input type="radio" name="picked" value="one" v-model="$store.state.active_experiment_data.picked">
            <input type="radio" name="picked" value="two" v-model="$store.state.active_experiment_data.picked">
          </p>
          <p>
            <select v-model="$store.state.active_experiment_data.selected">
              <option>one</option>
              <option>two</option>
            </select>
          </p>
          <p>
            <select v-model="$store.state.active_experiment_data.multiSelect" multiple>
              <option>one</option>
              <option>two</option>
              <option>three</option>
            </select>
          </p>
          <button type="submit">{{ $t('elements.update_experiment') }}</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ExperimentViewer",
  data() {
    return {}
  },
  methods: {
    send_experiment() {
      console.log(this.$data);
    }
  },
  mounted() {
    this.$store.dispatch("checkLogin");
  }
}
</script>

<style scoped>

</style>