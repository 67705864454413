<template>
  <div>
    <div class="login_wrapper">
      <div class="animate form login_form">
        <section class="login_content">
          <h1>Login Form</h1>
          <div>
            <input type="text" class="form-control" v-model="username" placeholder="Username" required=""/>
          </div>
          <div>
            <input type="password" class="form-control" v-model="password" placeholder="Password" required=""/>
          </div>
          <div>
            <a v-on:click="login()" class="btn btn-default submit">Log in</a>
            <a class="reset_pass">Lost your password?</a>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
    }
  },
  methods: {
    login() {
      this.$store.dispatch("closeError");
      axios({
        method: "post",
        url: "/api/login",
        data: this.$data,
      }).then(response => {
        if (response.status === 200) {
          this.$store.state.logged_in = true;
          this.$store.commit("setRights", response.data["rights"]);
        } else {
          this.$store.state.logged_in = false;
          this.$store.commit("showError", "Wrong login data.");
        }
      }).catch(error => {
        this.$store.commit("showError", "Wrong login data.");
      });
    }
  },
  mounted() {
    document.body.classList.add("login");
  },
  destroyed() {
    document.body.classList.remove("login");
  }
}
</script>

<style>
</style>