<template>
  <div class="container body">
    <div class="main_container">
      <div class="col-md-3 left_col" style="flex-direction: column;position:fixed" id="main_menu_box">
        <div class="left_col scroll-view">
          <div class="profile clearfix">
            <div class="profile_info">
              <span>{{ $t('message.hello') }},</span>
              <h2>{{ this.$store.state.user.name }}</h2>
            </div>
          </div>
          <div id="sidebar-menu" class="main_menu_side hidden-print main_menu">
            <div class="menu_section active">
              <h3 style="height: 20px; margin-top: 20px">{{ $t('boards.experiments') }}</h3>
              <ul id="main_menu" class="nav side-menu">
                <li id="all_experiments">
                  <router-link v-on:click="showBoard('all_experiments')" to="./all-experiments">{{ $t('boards.all_experiments') }}</router-link>
                </li>
                <li id="video_experiments">
                  <router-link v-on:click="showBoard('video_experiments')" to="./video-experiments">{{ $t('boards.video_experiments') }}</router-link>
                </li>
                <li id="irt_experiments">
                  <router-link v-on:click="showBoard('irt_experiments')" to="./irt-experiments">{{ $t('boards.irt_experiments') }}</router-link>
                </li>
                <li id="screeners">
                  <router-link v-on:click="showBoard('screeners')" to="./screeners">{{ $t('boards.screeners') }}</router-link>
                </li>
                <li v-if="$store.state.user.rights.includes('statistics')"><a href="/stats">Converter statistics</a></li>
                <li v-if="$store.state.user.rights.includes('presentations')"><a href="/pptx">PPTX generator</a></li>
                <li  v-if="$store.state.user.rights.includes('user_administration')" id="admins">
                  <router-link v-on:click="showBoard('admins')" to="./admins">Admin</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 100%">
        <div class="top_nav" style="width: 100%; height: 100%">
          <div class="nav_menu" style="margin-right: 0">
            <div class="nav toggle">
              <a id="menu_toggle" v-on:click="showGlobalMenu()"><i class="fa fa-bars"></i></a>
            </div>
            <nav class="nav navbar-nav">
              <ul class=" navbar-right">
                <li class="nav-item dropdown open" style="padding-left: 15px;">
                  <select @change="changeLocale()" v-model="$store.state.locale">
                    <option
                        v-for="locale in $store.state.locales"
                        v-bind:value="locale.locale"
                        :selected="locale.locale == $store.state.locale">
                      {{ locale.language }}
                    </option>
                  </select>
                  <a class="user-profile dropdown-toggle" aria-haspopup="true" id="navbarDropdown"
                     data-toggle="dropdown" aria-expanded="false" v-on:click="showUserMenu()">
                    <!--                    <img v-bind:src=this.$store.state.user.avatar v-bind:alt=this.$store.state.user.name>-->
                    {{ this.$store.state.user.name }}
                  </a>
                  <div class="dropdown-menu dropdown-usermenu pull-right" aria-labelledby="navbarDropdown"
                       x-placement="bottom-start" id="userMenu"
                       style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(15px, 21px, 0px);">
                    <a class="dropdown-item" v-on:click="logOut()"><i
                        class="fa fa-sign-out pull-right"></i>{{ $t('message.log_out') }}</a>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>


<script>
import AllExperiments from "./boards/AllExperiments";
import VideoExperiments from "./boards/VideoExperiments";
import IRTExperiments from "./boards/IRTExperiments";
import ExperimentViewer from "./boards/ExperimentViewer";
import ScreenerCreator from "./boards/Screeners";
import Admins from "./boards/Admins"
import axios from "axios";
import VueRouter from "vue-router";
import Vue from 'vue'

Vue.use(VueRouter);

const routes = [
  {path: '/all_experiments', component: AllExperiments},
  {path: '/screeners', component: ScreenerCreator}
]

export default {
  name: "MainView",
  components: {
    AllExperiments,
    VideoExperiments,
    IRTExperiments,
    ExperimentViewer,
    ScreenerCreator,
    Admins,
  },
  data() {
    return {}
  },
  methods: {
    showBoard(new_board) {
      this.$store.dispatch("closeError");
      if (this.$store.state.logged_in) {
        // this.$store.state.active_board = new_board;
        let main_list = document.getElementById("main_menu");
        let list_items = main_list.getElementsByTagName('li');
        for (let i = 0; i <= list_items.length - 1; i++) {
          list_items[i].classList.remove("active");
        }
        document.getElementById(new_board).classList.add("active");
      }
    },
    getExperiments() {
      axios({
        method: "get",
        url: "/api/info/experiments"
      }).then(response => {
        this.$store.state.experiments = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    showUserMenu() {
      var userMenu = document.getElementById("userMenu");
      if (userMenu.classList.contains("show")) {
        userMenu.classList.remove("show");
      } else {
        userMenu.classList.add("show");
      }
    },
    showGlobalMenu() {
      document.getElementById("main_menu_box").style.display = "flex";
    },
    logOut() {
      this.$store.state.logged_in = false;
      this.$store.state.user.rights = [];
      this.$cookies.remove("fastest_session");
    },
    changeLocale() {
      this.$i18n.locale = this.$store.state.locale;
    },
  },
  mounted() {
    this.$store.dispatch("closeError");
    this.$store.dispatch("checkLogin");
    this.$store.dispatch("getUserInfo");
    document.body.classList.add("nav-md");
    this.getExperiments();
  },
  destroyed() {
    this.$store.dispatch("clearUserInfo");
    document.body.classList.remove("nav-md");
    this.$store.state.experiments = {};
  }
}
</script>


<style>
html {
  min-height: 100%; /* make sure it is at least as tall as the viewport */
  position: relative;
}

body {
  height: 100%; /* force the BODY element to match the height of the HTML element */
}

.container-flow {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: -1; /* Remove this line if it's not going to be a background! */
  width: 300px;
  height: 100%;
}

.active_area {
  width: 100%;
  height: 100%;
  background-color: #0b97c4;
}

.experiment {
  margin-left: 30px;
  width: 45%;
  min-width: 1100px;
  border: var(--button_color) solid 2px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px 20px 40px;
}

.experiment_creator {
}

.experiment_page_main {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-flow: wrap;
}
</style>
