<template>
  <div class="right_col experiment_page_main" role="main">
    <div class="experiment">
    <div class="row" style="display: inline-block;">
      <div class="tile_count">
        <h3 style="height: 40px;font-size: 39px">{{$t('boards.all_experiments')}}</h3>
      </div>
      <div class="tile_count">
        <h3 style="height: 40px">{{$t('boards.video_experiments')}}</h3>
        <table>
          <thead>
          <tr>
            <th>{{$t('experiment.project_name')}}</th>
            <th>{{$t('experiment.videos')}}</th>
            <th>{{$t('experiment.questions')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="experiment in $store.state.experiments.video_experiments">
            <th><a v-on:click="showExperiment(experiment.id)">{{experiment.id}} {{ experiment.project_name }}</a></th>
            <th>{{ experiment.videos }}</th>
            <th>{{ experiment.questions }}</th>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="tile_count">
        <h3 style="height: 40px">{{$t('boards.irt_experiments')}}</h3>
        <table>
          <thead>
          <tr>
            <th>{{$t('experiment.project_name')}}</th>
            <th>{{$t('experiment.questions')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="experiment in $store.state.experiments.irt_experiments">
            <th><a v-on:click="showExperiment(experiment.id)">{{ experiment.project_name }}</a></th>
            <th>{{ experiment.questions }}</th>
          </tr>
          </tbody>
        </table>
      </div>
       <div class="tile_count">
        <h3 style="height: 40px">{{$t('boards.screeners')}}</h3>
        <table>
          <thead>
          <tr>
            <th>{{$t('experiment.project_name')}}</th>
            <th>{{$t('experiment.questions')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="experiment in $store.state.experiments.irt_experiments">
            <th><a v-on:click="showExperiment(experiment.id)">{{ experiment.project_name }}</a></th>
            <th>{{ experiment.questions }}</th>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ExperimentViewer from "./ExperimentViewer";

export default {
  name: "AllExperiments",
  components: {
    ExperimentViewer
  },
  data() {
    return {}
  },
  methods: {
    getExperiments() {
      axios({
        method: "get",
        url: "/api/get_expemints"
      }).then(response => {
        this.$cookies.set("csrftoken", response.data.token);
        axios.defaults.headers.common["X-CSRFToken"] = response.data.token;
      }).catch(error => {
        console.log(error);
      });
    },
    showExperiment(new_experiment_id) {
      this.$store.state.active_experiment_id = new_experiment_id;
      this.$store.dispatch("get_experiment_info");
    }
  },
  mounted() {
    this.$store.dispatch("checkLogin");
  }
}
</script>

<style scoped>

</style>