<template>
  <div class="right_col experiment_page_main" role="main">
    <div>
      <div class="user_list">
        <div v-for="user in users" style="padding-bottom: 30px" class="user">
          <span>{{ user.name }}</span>
          <div>
            <label v-for="right in user.rights"><input type="checkbox">{{ right }}</label>
          </div>
          <button v-on:click="editExistingUser(user)">{{user.id}}</button>
        </div>
      </div>
      <div class="user_list">
        <input v-model="edit_user_data.name" name="name" placeholder="Name">
        <input v-model="edit_user_data.password" name="password" placeholder="Password">
        <input v-model="edit_user_data.repeat_password" name="repeat_password" placeholder="Repeat password">
        <button v-if="edit_user_data.id != 0" v-on:click="saveUser()">Update user</button>
        <button v-else v-on:click="saveUser()">Create user</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Admins",
  data() {
    return {
      users: [],
      edit_user_data: {
        id: 0,
        name: "",
        rights: ["presentations"],
        password: "",
        repeat_password: "",
      }
    }
  },
  mounted() {
    this.$store.dispatch("checkLogin");
    if (this.$store.state.user.rights.includes("user_administration")) {
      this.getUsers();
    } else {
      window.location.replace("/");
      console.log(this.$store.state.user.rights);
      alert("LOL")
    }
  },
  methods: {
    getUsers() {
      axios({
        method: "get",
        url: "/api/users"
      }).then(response => {
        this.users = response.data.users;
      }).catch(error => {
        console.log(error);
      });
    },
    saveUser() {
      let send_method = "";
      if (this.edit_user_data.id == 0) {
        send_method = "PUT";
      } else {
        send_method = "POST";
      }
      axios({
        method: send_method,
        url: "/api/users",
        data: this.edit_user_data,
      }).then(response => {
        this.users = response.data.users;
      }).catch(error => {
        console.log(error);
      });
    },
    editExistingUser(user) {
      this.edit_user_data = user;
    }
  }
}
</script>

<style scoped>
.user_list {
  display: flex;
  flex-direction: column;
}

.user {

}
</style>