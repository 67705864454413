<template>
  <div class="container body">
    <div class="right_col" style="min-height: 100vh">
      <router-view></router-view>
    </div>
  </div>
</template>


<script>
import AllExperiments from "./boards/AllExperiments";
import VideoExperiments from "./boards/VideoExperiments";
import IRTExperiments from "./boards/IRTExperiments";
import ExperimentViewer from "./boards/ExperimentViewer";
import Screener from "./boards/Screener";
import ScreenerCreator from "./boards/Screeners";
import axios from "axios";
import VueRouter from "vue-router";
import Vue from 'vue'

Vue.use(VueRouter);

const routes = [
  {path: '/all_experiments', component: AllExperiments},
  {path: '/screeners', component: ScreenerCreator}
]

export default {
  name: "MainView",
  components: {
    // CreateExperiment,
    AllExperiments,
    VideoExperiments,
    IRTExperiments,
    ExperimentViewer,
    ScreenerCreator,
    Screener,
  },
  data() {
    return {}
  },
  methods: {
    showBoard(new_board) {
      this.$store.dispatch("closeError");
      if (this.$store.state.logged_in) {
        this.$store.state.active_board = new_board;
        let main_list = document.getElementById("main_menu");
        let list_items = main_list.getElementsByTagName('li');
        for (let i = 0; i <= list_items.length - 1; i++) {
          list_items[i].classList.remove("active");
        }
        document.getElementById(new_board).classList.add("active");
      }
    },
    getExperiments() {
      axios({
        method: "get",
        url: "/api/info/experiments"
      }).then(response => {
        this.$store.state.experiments = response.data;
        console.log(this.$store.state.experiments);
      }).catch(error => {
        console.log(error);
      });
    },
    showUserMenu() {
      var userMenu = document.getElementById("userMenu");
      if (userMenu.classList.contains("show")) {
        userMenu.classList.remove("show");
      } else {
        userMenu.classList.add("show");
      }
    },
    showGlobalMenu() {
      document.getElementById("main_menu_box").style.display = "flex";
    },
    logOut() {
      this.$store.state.logged_in = false;
      this.$cookies.remove("fastest_session");
    },
    changeLocale() {
      this.$i18n.locale = this.$store.state.locale;
    },
  },
  mounted() {
    this.$store.dispatch("closeError");
    this.$store.dispatch("checkLogin");
    this.$store.dispatch("getUserInfo");
    document.body.classList.add("nav-md");
    this.getExperiments();
  },
  destroyed() {
    this.$store.dispatch("clearUserInfo");
    document.body.classList.remove("nav-md");
    this.$store.state.experiments = {};
  }
}
</script>


<style>
html {
  min-height: 100%; /* make sure it is at least as tall as the viewport */
  position: relative;
}

body {
  height: 100%; /* force the BODY element to match the height of the HTML element */
}

.container-flow {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: -1; /* Remove this line if it's not going to be a background! */
  width: 300px;
  height: 100%;
}

.active_area {
  width: 100%;
  height: 100%;
  background-color: #0b97c4;
}

.experiment {
  margin-left: 30px;
  width: 45%;
  min-width: 1100px;
  border: var(--button_color) solid 2px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px 20px 40px;
}

.experiment_creator {
}

.experiment_page_main {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-flow: wrap;
}
</style>
